
import Vue from "vue";

export default Vue.extend({
  name: "PresentedDocuments",

  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    errorMessages: {
      type: Object,
      default: () => ({}),
    }
  },

  computed: {
    documents() {
      return this.value;
    }
  },

  methods: {
    openDocument(url: string) {
      window.open(url, "_blank");
    }
  }
});
